import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import "./utils/i18n";

declare global {
  interface Window {
    API_URL: string;
    SENTRY_DSN: string;
    SENTRY_TRACES_RATE: string;
    SENTRY_ENVIRONMENT: string;
    YANDEX_METRIC_ID: string;
  }
}

const { SENTRY_DSN, SENTRY_TRACES_RATE, SENTRY_ENVIRONMENT } = window;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: +SENTRY_TRACES_RATE,
  environment: SENTRY_ENVIRONMENT,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
