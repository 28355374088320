/* eslint-disable @typescript-eslint/no-explicit-any */
// Version v1.0.2
import { ChangeEvent, FC, HTMLProps, useRef } from "react";
import styles from "./Dropzone.module.scss";
import classNames from "classnames";
import { ReactComponent as CloudIcon } from "./icons/cloud.svg";
import { ReactComponent as FileIcon } from "./icons/file-icon.svg";
import { ReactComponent as ForbiddenIcon } from "./icons/forbidden.svg";

export type Props = {
  onUpload: (files: FileList) => void;
  isDragging: boolean;
  isError: boolean;
  msg: string;
  accept: string[];
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const MyDropzone: FC<Props> = (props) => {
  const {
    accept,
    msg,
    isDragging,
    isError,
    onDrop,
    onDragEnter,
    onUpload,
    addClasses,
  } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {
      [styles["container-active"]]: isDragging,
      [styles["container-error"]]: isError,
    }
  );

  const messageClass = classNames([styles["msg"]], {
    [styles["msg-error"]]: isError,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleInputUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = inputRef.current?.files;
    if (files) {
      onUpload(files);
    }
    inputRef.current!.value = "";
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    onDragEnter && onDragEnter(e);
  };
  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    onDrop && onDrop(e as any);
  };

  const icon = isError ? (
    <ForbiddenIcon className={styles["forbidden-icon"]} />
  ) : isDragging ? (
    <CloudIcon className={styles["cloud-icon"]} />
  ) : (
    <FileIcon className={styles["file-icon"]} />
  );

  return (
    <div
      className={className}
      {...props}
      onClick={handleClick}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragEnter={handleDragEnter}
      onDrop={handleDrop as any}
    >
      {icon}
      {msg.split("\n").map((l, i) => (
        <span className={messageClass} key={i}>
          {l}
        </span>
      ))}
      <input
        ref={inputRef}
        className={styles["input"]}
        type="file"
        accept={accept.join(",")}
        onChange={handleInputUpload}
      />
    </div>
  );
};

export default MyDropzone;
