import { useTranslation } from "react-i18next";
import { FC, HTMLProps } from "react";
import styles from "./HeadCount.module.scss";
import classNames from "classnames";

export type Props = {
  count: number | null;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const HeadCount: FC<Props> = (props) => {
  const { count, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {
      [styles["hidden"]]: count == null,
    }
  );
  const { t } = useTranslation();

  return (
    <div className={className} {...props}>
      <span>
        {t("counter.found")}: {count}
      </span>
    </div>
  );
};

export default HeadCount;
