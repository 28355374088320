import { useState } from "react";

type State = "default" | "file_limit_exceeded" | "type_not_allowed" | "upload";

const useDragAndDrop = (
  allowedTypes: string[],
  limit: number,
  uploadCb: (files: FileList) => void
) => {
  const [currentState, setCurrentState] = useState<State>("default");
  const [isDragging, setIsDragging] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleUpload = (files: FileList) => {
    uploadCb(files);
  };

  const onDragEnter = (e: DragEvent) => {
    if (!e.dataTransfer) return;
    if (e.dataTransfer.items.length > limit) {
      setIsError(true);
      setCurrentState("file_limit_exceeded");
      return;
    }
    setIsDragging(true);
    const fileExtension = e.dataTransfer.items[0].type.split("/")[1];
    const fileExtensionAllowed = allowedTypes.includes(fileExtension);
    if (!fileExtensionAllowed) {
      setCurrentState("type_not_allowed");
      setIsError(true);
    } else {
      setCurrentState("upload");
    }
  };

  const onDragLeave = () => {
    setIsDragging(false);
    setCurrentState("default");
    setIsError(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDrop = (e: any) => {
    if (isError) {
      setIsDragging(false);
      setCurrentState("default");
      setIsError(false);
      return;
    }
    setIsDragging(false);
    setCurrentState("default");
    const files = e.dataTransfer?.files;
    if (files) {
      handleUpload(files);
    }
  };
  return {
    onDragEnter,
    onDragLeave,
    onDrop,
    isDragging,
    isError,
    currentState,
  };
};

export default useDragAndDrop;
