import ImageList from "./ImageList";
import Masonry from "react-masonry-css";
import { FC, HTMLProps } from "react";
import styles from "./PreloadedImgs.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export type Props = {
  onSelection: (imgSrc: string) => void;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const PreloadedImgs: FC<Props> = (props) => {
  const { onSelection, addClasses } = props;
  const { t } = useTranslation();
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return (
    <div className={className} {...props}>
      <p className={styles["title"]}>{t("img.preloaded")}</p>
      <Masonry
        breakpointCols={{ default: 4, 1100: 3, 700: 2, 500: 1 }}
        className={styles["my-masonry-grid"]}
        columnClassName={styles["my-masonry-grid_column"]}
      >
        {ImageList.map((src) => {
          return (
            <img
              className={styles["masonry-img"]}
              key={src}
              src={src}
              onClick={() => {
                onSelection(src);
              }}
              alt=""
            />
          );
        })}
      </Masonry>
    </div>
  );
};

export default PreloadedImgs;
