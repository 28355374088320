import colormap from "colormap";
import { DataArray } from "tiff/lib/types";
import * as tiff from "tiff";
import { Buffer } from "buffer";

const colormapColors = colormap({
  colormap: "jet",
  format: "rgba",
  nshades: 256,
  alpha: [0.3, 1],
});

export const clearCanvas = (canvas: HTMLCanvasElement) => {
  const context = canvas.getContext("2d");
  context?.clearRect(0, 0, canvas.width, canvas.height);
  canvas.width = 300;
  canvas.height = 150;
};

export const drawHeatmap = (
  heatmapCanvas: HTMLCanvasElement,
  heatmap: string,
  image: HTMLImageElement
) => {
  const heatmapCtx = heatmapCanvas.getContext("2d");
  const arrayBuffer = Buffer.from(heatmap, "base64");
  const decodedTiff = tiff.decode(arrayBuffer)[0];
  const dataArray = decodedTiff.data;
  const convertedToRGB = normalize(dataArray);
  const collorMapped = mapColors(convertedToRGB);
  heatmapCanvas.width = decodedTiff.width;
  heatmapCanvas.height = decodedTiff.height;
  const updatedImageData = new ImageData(
    collorMapped,
    decodedTiff.width,
    decodedTiff.height
  );
  heatmapCtx!.putImageData(updatedImageData, 0, 0);
  image.src = heatmapCanvas.toDataURL();
};

export const normalize = (floatArray: DataArray) => {
  const arr = Array.from(floatArray);
  const max = getMax(arr);
  const min = getMin(arr);
  for (let i = 0; i < floatArray.length; i++) {
    floatArray[i] = Math.trunc(
      (255 * (floatArray[i] - min)) / (max - min + 10 ** -5)
    );
  }
  return floatArray;
};

export function getMax(arr: number[]) {
  return arr.reduce((max, v) => (max >= v ? max : v), -Infinity);
}

export function getMin(arr: number[]) {
  return arr.reduce((min, v) => (min <= v ? min : v), Infinity);
}

export const mapColors = (normalizedFloatArray: DataArray) => {
  const newPixelsArr = [];
  for (let i = 0; i < normalizedFloatArray.length; i++) {
    const shadow = normalizedFloatArray[i];
    const rgbColor = colormapColors[shadow];
    newPixelsArr[i * 4] = rgbColor[0];
    newPixelsArr[i * 4 + 1] = rgbColor[1];
    newPixelsArr[i * 4 + 2] = rgbColor[2];
    newPixelsArr[i * 4 + 3] = rgbColor[3] * 255;
  }
  return new Uint8ClampedArray(newPixelsArr);
};
