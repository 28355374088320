import styles from "./Navigation.module.scss";
import Logo from "../Logo/Logo";
import Description from "../Description";
import { useState } from "react";
import Language from "../Language";
import { useTranslation } from "react-i18next";
import { FC, HTMLProps } from "react";
import classNames from "classnames";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const Navigation: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const { i18n } = useTranslation();
  const [descriptionOpened, setDescriptionOpened] = useState(true);

  const toggleDescription = () => {
    setDescriptionOpened((prev) => !prev);
  };

  return (
    <div className={className} {...props}>
      <Logo addClasses={[styles["logo"]]} handleClick={toggleDescription} />
      <div className={styles["lang-container"]}>
        {/* <h4>v1.0.1</h4> */}
        <Language
          title="Ru"
          onClick={() => i18n.changeLanguage("ru")}
          selected={i18n.language.includes("ru")}
        />
        <Language
          title="En"
          onClick={() => i18n.changeLanguage("en")}
          selected={i18n.language.includes("en")}
        />
      </div>
      <Description
        opened={descriptionOpened}
        addClasses={[styles["description"]]}
      />
    </div>
  );
};

export default Navigation;
