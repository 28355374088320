import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          img: {
            upload: "Drop your image here or click for selection",
            wrong: "Wrong image type",
            preloaded: "or select preloaded one",
            reload: "repeat",
          },
          counter: {
            found: "People found",
          },
          language: {
            switch: "РУ",
          },
          demo: {
            name: "Crowd Estimation",
            description_1:
              "TevianCrowd module is designed to count the number of people in an image. Simply upload an image of a crowd and find out how many people are in the frame. People can occupy just a few pixels. TevianCrowd works with different densities of people in the far and near backgrounds, partial presence of people in the photo (in the case of a photo with only people's heads).",
            description_2:
              "Use case TevianCrowd: control of places attendance, analytics of occupancy fluctuations, control of overcrowding in public places, highlighting areas of people's interest",
          },
          dragAndDrop: {
            default: "Drop your image here\nor click for selection",
            file_limit_exceeded: "Max {limit} file(s) allowed",
            type_not_allowed: "The file is not allowed. Allowed types: {types}",
            upload: "Drop image for uploading to server",
          },
          policy: "Privacy policy",
        },
      },
      ru: {
        translation: {
          img: {
            upload: "Перетащите сюда или кликните для загрузки изображения",
            wrong: "Неверный формат изображения",
            preloaded: "или выберите одно из демонстрационных",
            reload: "повторить",
          },
          counter: {
            found: "Найдено человек",
          },
          language: {
            switch: "EN",
          },
          demo: {
            name: "Подсчет числа людей",
            description_1:
              "Модуль TevianCrowd предназначен для подсчета числа людей на изображении. Просто загрузите картинку толпы и узнайте, какое количество человек попало в кадр. При этом люди могут занимать всего несколько пикселей. TevianCrowd работает с разной плотностью людей на дальнем и ближнем плане, частичным нахождением людей на фото (например, в случае фотографии только с головами людей).",
            description_2:
              "Возможные применения TevianCrowd: контроль посещаемости мест, аналитика колебаний наполненности помещений, контроль переполненности общественных мест, выделение зон интереса людей",
          },
          dragAndDrop: {
            default: "Переместите изображение\nили кликните для выбора",
            file_limit_exceeded:
              "Максимально допустимое количество файлов: {limit}",
            type_not_allowed:
              "Недопустимое разрешение файла. Допустимые разрешения: {types}",
            upload: "Отпустите изображение для загрузки на сервер",
          },
          policy: "Политика конфиденциальности",
        },
      },
    },
  });

export default i18n;
