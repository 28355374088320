import axios from "axios";

const { API_URL } = window;

export const estimate = (image: Blob) => {
  var bodyFormData = new FormData();
  bodyFormData.set(
    "params",
    new Blob([JSON.stringify([])], {
      type: "application/json",
    })
  );
  bodyFormData.set("image", image);
  return axios({
    method: "POST",
    url: `${API_URL}/estimate`,
    headers: {
      "Content-Type": "multipart/form-data; boundary=EXAMPLEBOUNDARY",
    },
    data: bodyFormData,
  });
};
