import Img0 from "../../imgs/0.jpg";
import Img1 from "../../imgs/1.jpg";
import Img2 from "../../imgs/2.jpg";
import Img3 from "../../imgs/3.jpg";
import Img4 from "../../imgs/4.jpg";
import Img5 from "../../imgs/5.jpg";
import Img7 from "../../imgs/7.jpg";
import Img8 from "../../imgs/8.jpg";
import Img9 from "../../imgs/9.jpg";
import Img10 from "../../imgs/10.jpg";
import Img11 from "../../imgs/11.jpg";
import Img12 from "../../imgs/12.jpg";
import Img13 from "../../imgs/13.jpg";
import Img14 from "../../imgs/14.jpg";
import Img1280 from "../../imgs/1280.jpg";
import ImgHard from "../../imgs/hard.jpg";
import ImgMedium from "../../imgs/medium.jpg";
import ImgQueue from "../../imgs/queue.jpg";
import ImgSquare from "../../imgs/square.jpg";
import ImgVeryHard from "../../imgs/veryhard.jpg";

const ImageList = [
  Img0,
  ImgMedium,
  ImgSquare,
  Img1,
  Img3,
  Img5,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img1280,
  ImgHard,
  ImgQueue,
  ImgVeryHard,
  Img2,
  Img4,
];

export default ImageList;
