import { Alert, Button } from "@mui/material";
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import PreloadedImgs from "./components/PreloadedImgs";
import { clearCanvas, drawHeatmap } from "./utils/functions";
import ReplayIcon from "@mui/icons-material/Replay";
import { estimate } from "./utils/api";
import HeadCount from "./components/HeadCount";
import Spinner from "./components/Spinner";
import Navigation from "./components/Navigation";
import BottomSection from "./components/BottomSection/BottomSection";
import { useTranslation } from "react-i18next";
import Dropzone from "./components/Dropzone";
import useDragAndDrop from "./components/Dropzone/useDragAndDrop";

const DND_FILE_LIMIT = 1;
const DND_ALLOWED_TYPES = ["jpeg", "jpg"];

function App() {
  const [showFileSelection, setShowFileSelection] = useState(true);
  const [showRepeatButton, setShowRepeatButton] = useState(false);
  const [headCount, setHeadCount] = useState<number | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState("");
  const heatmapCanvasRef = useRef<HTMLCanvasElement>(null);
  const initialImageRef = useRef<HTMLImageElement>(null);
  const heatmapImageRef = useRef<HTMLImageElement>(null);
  const { t } = useTranslation();

  const handleSelectedFile = (files: File[] | FileList | null) => {
    const reader = new FileReader();
    if (files && files.length) {
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const initialImage = initialImageRef.current!;
        initialImage.src = reader.result as string;
        makeRequest(reader.result as string);
      };
      setShowFileSelection(false);
    }
  };
  const {
    onDragEnter,
    onDragLeave,
    onDrop,
    isDragging,
    isError,
    currentState,
  } = useDragAndDrop(DND_ALLOWED_TYPES, DND_FILE_LIMIT, handleSelectedFile);

  const makeRequest = async (imgSrc: string) => {
    try {
      setShowLoader(true);
      setError("");
      const image = await fetch(imgSrc).then((res) => res.blob());
      const res: {
        data: {
          head_count: number;
          heatmap: string;
          polygon_head_count: number[];
        };
      } = await estimate(image);
      setHeadCount(Math.round(res.data.head_count));
      const heatmapCanvas = heatmapCanvasRef.current!;
      drawHeatmap(heatmapCanvas, res.data.heatmap, heatmapImageRef.current!);
    } catch (e: any) {
      setError(e.response.data.message);
    } finally {
      setShowLoader(false);
      setShowRepeatButton(true);
    }
  };

  const handleRepeatButtonClick = () => {
    setError("");
    setShowFileSelection(true);
    setShowRepeatButton(false);
    clearCanvas(heatmapCanvasRef.current!);
    setHeadCount(null);
    initialImageRef.current!.src = "";
    heatmapImageRef.current!.src = "";
  };

  const handlePreloadedImageSelect = (imgSrc: string) => {
    const initialImage = initialImageRef.current!;
    initialImage.src = imgSrc;
    makeRequest(imgSrc);
    setShowFileSelection(false);
  };

  const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    (e.target as HTMLImageElement).style.display = "none";
  };

  const handleImageLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    (e.target as HTMLImageElement).style.display = "block";
  };

  const dndMsg = useMemo(() => {
    switch (currentState) {
      case "file_limit_exceeded":
        return t(`dragAndDrop.${currentState}`, { limit: DND_FILE_LIMIT });
      case "type_not_allowed":
        return t(`dragAndDrop.${currentState}`, {
          types: DND_ALLOWED_TYPES.join(","),
        });
      default:
        return t(`dragAndDrop.${currentState}`);
    }
  }, [t]);

  useEffect(() => {
    (window as any).ym(window.YANDEX_METRIC_ID, "hit", window.location.href);
  }, []);

  return (
    <div className="app-container">
      <div className="max-width">
        <Navigation />
        {error && <Alert severity="error">{error}</Alert>}
        <div
          style={{
            display: showFileSelection ? "flex" : "none",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Dropzone
            onUpload={handleSelectedFile}
            isDragging={isDragging}
            isError={isError}
            msg={dndMsg}
            accept={["image/*", "application/pdf"] as any}
            onDragEnter={onDragEnter as any}
            onDragLeave={onDragLeave as any}
            onDrop={onDrop}
          />
          <PreloadedImgs onSelection={handlePreloadedImageSelect} />
        </div>
        <div className="body">
          <div className="result-container">
            <HeadCount count={headCount} />
            <div className="heatmap-container">
              <img
                ref={initialImageRef}
                onError={handleImageError}
                onLoad={handleImageLoad}
                alt=""
                className={"original-img"}
              />
              <img
                ref={heatmapImageRef}
                onError={handleImageError}
                onLoad={handleImageLoad}
                alt=""
                className={"heatmap-img"}
              />
              <canvas ref={heatmapCanvasRef} />
              {showLoader && <Spinner addClasses={["spinner"]} />}
            </div>
            {showRepeatButton && (
              <Button
                onClick={handleRepeatButtonClick}
                startIcon={<ReplayIcon />}
              >
                {t("img.reload")}
              </Button>
            )}
          </div>
        </div>
      </div>
      <BottomSection />
    </div>
  );
}

export default App;
